import dynamic from 'next/dynamic';

import NextLink from '@shared/common/components/NextLink';

import { useOnboarding } from './OnboardingProvider';

const NotificationBannerPortal = dynamic(
  () => import('../NotificationBannerProvider/NotificationBannerPortal'),
  { ssr: false },
);

const OnboardingBanner = () => {
  const { onboardingState, activeStep, isEnabled, steps, activeStepIndex, start, resume } =
    useOnboarding();

  if (!isEnabled || !activeStep) {
    return null;
  }

  return (
    <NotificationBannerPortal priority={1}>
      <div className="notification-banner notification-banner-red">
        <span>
          {isEnabled && onboardingState?.isFirstTime && (
            <>
              Welcome on board! Complete these steps and earn your first 100 Karma Points!{' '}
              {activeStep?.pathname ? (
                <NextLink href={activeStep.pathname} onClick={start} className="text-blue-600">
                  Get Started
                </NextLink>
              ) : (
                <button onClick={start} className="text-blue-600">
                  Get Started
                </button>
              )}
            </>
          )}

          {isEnabled && activeStep && !onboardingState?.isFirstTime && (
            <>
              Step {activeStepIndex + 1}/{steps.length}. {activeStep.step.title}{' '}
              {activeStep.step.content}{' '}
              {activeStep.pathname ? (
                <NextLink href={activeStep.pathname} onClick={resume} className="text-blue-600">
                  Continue
                </NextLink>
              ) : (
                <button onClick={resume} className="text-blue-600">
                  Continue
                </button>
              )}
            </>
          )}
        </span>
      </div>
    </NotificationBannerPortal>
  );
};

export default OnboardingBanner;
