import { useCallback, useLayoutEffect, useState } from 'react';

import { useAuth } from '@shared/common/providers/AuthProvider';

const ONBOARDING_STATE_STORAGE_KEY = 'onboarding-state';

export interface UserOnboardingState {
  isCompleted: boolean;
  activeStepIndex?: number;
  /**
   * is true user hasn't started tour yet
   */
  isFirstTime: boolean;
}

type OnboardingState = Record<string, UserOnboardingState>;

const getStoredOnboardingState = (): OnboardingState => {
  if (typeof localStorage !== 'undefined') {
    const rawData = localStorage.getItem(ONBOARDING_STATE_STORAGE_KEY);

    try {
      if (rawData) {
        const parsedData = JSON.parse(rawData);

        if (typeof parsedData === 'object') {
          return parsedData;
        }
      }
    } catch (e) {
      // Do nothing
    }
  }

  return {};
};

const storeOnboardingState = (state: OnboardingState) => {
  localStorage.setItem(ONBOARDING_STATE_STORAGE_KEY, JSON.stringify(state));
};

const getStoredUserOnboardingState = (userId?: string | number): UserOnboardingState => {
  const storedData = getStoredOnboardingState();
  const defaultState: UserOnboardingState = {
    isCompleted: false,
    isFirstTime: true,
  };

  if (!userId) {
    return defaultState;
  }

  return {
    ...defaultState,
    ...storedData[userId],
  };
};

const storeUserOnboardingState = (userId: string | number, state: UserOnboardingState) => {
  const storedData = getStoredOnboardingState();

  storeOnboardingState({
    ...storedData,
    [userId]: state,
  });
};

const useOnboardingState = () => {
  const { user } = useAuth();
  const [onboardingState, setOnboardingState] = useState<UserOnboardingState>();
  const updateOnboardingState = useCallback((onboardigState: Partial<UserOnboardingState>) => {
    setOnboardingState((oldState) => {
      if (!oldState) {
        return oldState;
      }

      return {
        ...oldState,
        ...onboardigState,
      };
    });
  }, []);

  // sets user's stored onboarding state after login
  useLayoutEffect(() => {
    if (!user) {
      setOnboardingState(undefined);

      return;
    }

    setOnboardingState(getStoredUserOnboardingState(user?.id));
  }, [user]);

  // updates stored user's onbaording state
  useLayoutEffect(() => {
    if (!user || !onboardingState) {
      return;
    }

    storeUserOnboardingState(user.id, onboardingState);
  }, [onboardingState, user]);

  return {
    updateOnboardingState,
    onboardingState,
  };
};

export default useOnboardingState;
