'use client';
import React, { ReactNode, useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';

import { useAuth } from '@shared/common/providers/AuthProvider';
import { WIDGET_CONTAINER_ID } from '@shared/common/providers/WidgetContainer';
import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

const createOneSignalConnect = async () => {
  await OneSignal.init({
    appId: process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID || '',
    allowLocalhostAsSecureOrigin: !!process.env.NEXT_PUBLIC_ONE_SIGNAL_ALLOW_LOCALHOST,
    path: `${process.env.NEXT_PUBLIC_BASE_PATH}/` || '/',
    notifyButton: {
      enable: true,
    },
  });

  await OneSignal.Slidedown.promptPush();

  const bellElement = document.querySelector('body > #onesignal-bell-container');
  const container = document.querySelector(`#${WIDGET_CONTAINER_ID}`);

  if (bellElement && container) {
    bellElement.classList.add(
      'onesignal-bell-widget', // NOTE: style class defined in tailwind config
      '-order-1', // to make it on top of other widgets
    );
    container.appendChild(bellElement);
  }

  return OneSignal;
};

const OneSignalProvider = ({ children }: { children: ReactNode }) => {
  const [oneSignalInstance, setOneSignalInstance] = useState<typeof OneSignal | null>(null);
  const featureToggles = useFeatureToggles();
  const { user, isAuth } = useAuth();

  useEffect(() => {
    if (!featureToggles.notification || oneSignalInstance) {
      return;
    }

    const init = async () => {
      const connect = await createOneSignalConnect();

      setOneSignalInstance(connect);
    };

    init();
  }, [featureToggles.notification, oneSignalInstance]);

  useEffect(() => {
    if (!isAuth || !user?.id || !oneSignalInstance) {
      return;
    }

    const loginUser = async () => {
      try {
        await oneSignalInstance.login(String(user.id));
        await oneSignalInstance.User.addAlias('userId', String(user.id));
      } catch (error) {
        console.error(error);
      }
    };

    loginUser();
  }, [oneSignalInstance, user?.id, isAuth]);

  return <>{children}</>;
};

export default OneSignalProvider;
