'use client';

import dynamic from 'next/dynamic';

import React, { ReactNode } from 'react';

const AuthModal = dynamic(
  () => import('@store:web/components/common/modals/AuthModal').then((el) => el.AuthModal),
  {
    ssr: false,
  },
);
const ConnectAndSignModal = dynamic(
  () => import('@shared/common/components/modals/ConnectAndSignModal'),
  {
    ssr: false,
  },
);
const CompleteMagicIDModal = dynamic(
  () => import('@store:web/components/common/modals/CompleteMagicIDModal/CompleteMagicIDModal'),
  {
    ssr: false,
  },
);
const EmailLoginError = dynamic(
  () => import('@store:web/components/common/modals/EmailLoginError'),
  {
    ssr: false,
  },
);

const RateLimitedModal = dynamic(
  () => import('@store:web/components/common/modals/RateLimitedModal'),
  {
    ssr: false,
  },
);

const SupportModal = dynamic(() => import('@shared/common/components/modals/SupportModal'));

const BanxaModal = dynamic(() => import('@shared/common/components/modals/BanxaModal'));

const CustomContentModal = dynamic(
  () => import('@shared/common/components/modals/CustomContentModal'),
);

const GlobalModalsStore = ({ children }: { children: ReactNode }) => {
  return (
    <>
      {children}
      <ConnectAndSignModal />
      <AuthModal />
      <CompleteMagicIDModal />
      <EmailLoginError />
      <RateLimitedModal />
      <BanxaModal />
      <CustomContentModal />
      <SupportModal />
    </>
  );
};

export default GlobalModalsStore;
