'use client';

import dynamic from 'next/dynamic';
import { usePathname, useRouter } from 'next/navigation';

import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import type { CallBackProps } from 'react-joyride';

import { differenceInDays } from 'date-fns';

import { Modal, ModalCloseButton, ModalContent, ModalHeading, useModalController } from '@uikit';

import { useAuth } from '@shared/common/providers/AuthProvider';
import { nextProjectUrl } from '@shared/constants/url';
import { createSafeContext, useSafeContext } from '@shared/helpers/context';
import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

import useUserWalletStatusFlags from '@store:web/utils/hooks/useWeb3/useUserWalletStatusFlags';

import OnboardingBanner from './OnboardingBanner';
import useOnboardingState, { UserOnboardingState } from './useOnboardingState';
import useOnboardingSteps, { OnboardingStep, STEP_NAME } from './useOnboardingSteps';

const Joyride = dynamic(() => import('react-joyride'), {
  ssr: false,
});

interface OnboardingProviderProps {
  children?: ReactNode;
}

interface ContextValue {
  stop: () => void;
  resume: () => void;
  start: () => void;
  steps: OnboardingStep[];
  activeStepIndex: number;
  activeStep?: OnboardingStep;
  /**
   * if true user can participate in oboarding (user should be auth, onboarding is not completed, etc)
   */
  isEnabled: boolean;
  /**
   * if true, onboarding is currently running (shows highlights, redirect on specific pages, etc)
   */
  isActive: boolean;
  onboardingState?: UserOnboardingState;
}

const Context = createSafeContext<ContextValue>();

export const useOnboarding = () => useSafeContext(Context);

const isStepPathnameMatches = (step: OnboardingStep, pathname: string) => {
  if (!step?.pathname) {
    return true;
  }

  return step.exactPathname ? step.pathname === pathname : pathname.startsWith(step.pathname);
};

// TODO: refactor
const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
  const congratsModal = useModalController();
  const { isAuth, user } = useAuth();
  const { isEMVWalletConnected } = useUserWalletStatusFlags();
  const isSelfEmailVerified = !!user?.attributes.email;
  const isSelfEVMWalletConnected = isEMVWalletConnected;
  const isSellUsernameSet = !!user?.attributes.name;
  const isSelfDisplaynameSet = !!user?.attributes.displayedName;
  const featureToggles = useFeatureToggles();
  const pathname = usePathname();
  const router = useRouter();
  const { onboardingState, updateOnboardingState } = useOnboardingState();
  const isNewAccount = useMemo(() => {
    return !!user && differenceInDays(new Date(), new Date(user.attributes.createdAt)) < 14;
  }, [user]);
  const isEnabled =
    !!featureToggles.storeTour &&
    isAuth &&
    !!onboardingState &&
    (isNewAccount || (!isNewAccount && !onboardingState.isFirstTime)) &&
    !onboardingState.isCompleted;
  const [isActive, setIsActive] = useState(false);
  const steps = useOnboardingSteps();
  const joyrideSteps = useMemo(() => {
    return steps.map((step) => step.step);
  }, [steps]);
  const { activeStepIndex = 0 } = onboardingState || {};
  const activeStep: undefined | OnboardingStep = steps[activeStepIndex];

  // changes step on the next one, if current step that's related to adding wallet or email is already done
  useEffect(() => {
    if (!isEnabled || isActive) {
      return;
    }

    const isWalletAndEmailStepCompleted =
      activeStep?.name === STEP_NAME.CONNECT_WALLET_OR_VERIFY_EMAIL &&
      isSelfEmailVerified &&
      isSelfEVMWalletConnected;
    const isDisplaynameAndUsernameStepCompleted =
      activeStep?.name === STEP_NAME.SET_USERNAME_AND_DISPLAYNAME &&
      isSelfDisplaynameSet &&
      isSellUsernameSet;

    if (isWalletAndEmailStepCompleted || isDisplaynameAndUsernameStepCompleted) {
      updateOnboardingState({
        activeStepIndex: activeStepIndex + 1,
      });
    }
  }, [
    activeStep,
    isActive,
    isSelfEmailVerified,
    updateOnboardingState,
    isSelfEVMWalletConnected,
    activeStepIndex,
    isEnabled,
    isSelfDisplaynameSet,
    isSellUsernameSet,
  ]);

  const controls = useMemo(() => {
    const start: ContextValue['start'] = () => {
      updateOnboardingState({
        activeStepIndex: 0,
        isFirstTime: false,
      });

      setIsActive(true);
    };

    const stop = () => {
      setIsActive(false);
    };

    const resume = () => {
      if (activeStep?.onBeforeStep) {
        activeStep.onBeforeStep();
      }

      setIsActive(true);
    };

    return {
      start,
      resume,
      stop,
    };
  }, [updateOnboardingState, activeStep?.onBeforeStep]);
  const value = useMemo(() => {
    return {
      activeStep,
      onboardingState,
      isEnabled,
      isActive,
      steps,
      activeStepIndex,
      ...controls,
    };
  }, [activeStep, controls, onboardingState, activeStepIndex, isEnabled, isActive, steps]);

  // locks scroll and scrolls on top of the page if step is require it
  useEffect(() => {
    if (isActive && activeStep && activeStep?.lockScroll) {
      window.scrollTo({
        top: 0,
      });
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [activeStep, isActive]);

  const handleCallback = ({ type, action, index }: CallBackProps) => {
    if (action === 'skip' || action === 'close' || (action === 'stop' && type !== 'tour:status')) {
      setIsActive(false);

      return;
    }

    const currentStep = steps[index];

    // redirect to a pathname that required for completing step
    if (
      (action === 'next' || type === 'tour:start') &&
      currentStep.pathname &&
      !isStepPathnameMatches(currentStep, pathname)
    ) {
      router.push(currentStep.pathname);

      return;
    }

    const nextStepIndex = index + 1;
    const nextStep = steps[nextStepIndex];

    if (type === 'step:after') {
      if (typeof currentStep.isCompleted === 'boolean' && !currentStep.isCompleted) {
        // open profile edit modal for steps that require filling profile
        if (
          currentStep.name === STEP_NAME.CONNECT_WALLET_OR_VERIFY_EMAIL ||
          currentStep.name === STEP_NAME.SET_USERNAME_AND_DISPLAYNAME
        ) {
          router.replace(nextProjectUrl.store(`/profile/settings?fillProfile=1`));
        }

        setIsActive(false);

        return;
      }

      if (currentStep.onAfterStep) {
        currentStep.onAfterStep();
      }

      if (nextStep?.onBeforeStep) {
        nextStep.onBeforeStep();
      }

      const isLastStep = !nextStep;

      updateOnboardingState({
        isCompleted: isLastStep,
        activeStepIndex: nextStepIndex,
      });

      if (isLastStep) {
        congratsModal.open();

        setIsActive(false);
      }
    }
  };

  return (
    <Context.Provider value={value}>
      <OnboardingBanner />
      {children}
      <Joyride
        stepIndex={activeStepIndex}
        // only run if onboarding is active (user continued tour) and page is right ()
        run={isActive && isStepPathnameMatches(activeStep, pathname)}
        steps={joyrideSteps}
        continuous
        floaterProps={{
          // disable flipping because when user scrolls it behaves buggy
          disableFlip: true,
        }}
        hideBackButton
        locale={{ skip: 'Close', last: 'Close', next: 'Continue' }}
        callback={handleCallback}
        showSkipButton
      />

      <Modal {...congratsModal.props}>
        <ModalContent className="py-4 px-4 sm:px-12 pt-6 sm:pb-6">
          <ModalCloseButton className="!mb-0 ml-auto" />

          <ModalHeading className="!mb-4">Congrats!</ModalHeading>

          <p className="text-center">Welcome to the world of magic and rewards!</p>

          <button
            onClick={congratsModal.close}
            className="button button-solid-primary button-lg mt-8 w-full"
          >
            Close
          </button>
        </ModalContent>
      </Modal>
    </Context.Provider>
  );
};

export default OnboardingProvider;
